import React from 'react'

const CompetitorFaq = () => {
  return (
    <div className="page--competitor-faq m-8 mb-12 wrapper">
      <h1>Common Questions When Coming to Couchbase From Other Database Platforms</h1>

      <p>
        Welcome to our commonly asked questions from developers whom are using knowledge they have gained from other database platforms as a foundation to learn Couchbase.
        If you have a question that isn't answered here, please <a target="_blank" rel="noopener noreferrer" href="https://www.couchbase.com/contact">contact us</a>.
      </p>

      <p>
        <stong>I'm new to Couchbase and see there are several versions of the software. What is the difference between Couchbase Community Edition, Enterprise Edition, and Cloud Edition?</stong>
      </p>
      <p>
        Couchbase has several versions of our software which can be found on our <a target="_blank" rel="noopener noreferrer" href="https://www.couchbase.com/products/editions">Compare Features</a> page.
      </p>

      <p>
        <strong>How does Couchbase Cloud compare to other cloud based solutions?</strong>
      </p>
      <p>
        Couchbase <a target="_blank" rel="noopener noreferrer" href="https://www.couchbase.com/products/cloud">Cloud Database-As-A-Service</a>
        documentation explains our offerings that compare to other cloud products.
      </p>

      <p>
        <strong>Are there any whitepapers that compare the other big NoSQL vendors to Couchbase?</strong>
      </p>
      <p>
        We have several whitepapers published on our <a target="_blank" rel="noopener noreferrer" href="https://resources.couchbase.com/c/no-sql-technical-com?x=N-I_ik">Resources site</a>.
      </p>

      <p>
        <strong>What licensing models does Couchbase use?</strong>
      </p>

      <p>
        Couchbase <a target="_blank" rel="noopener noreferrer" href="https://www.couchbase.com/licensing-and-support-faq">Licensing FAQ</a> can answer many questions based on which version you are looking to use.
        For a full listing of open source projects, you can check out our <a target="_blank" rel="noopener noreferrer" href="https://developer.couchbase.com/open-source-projects/">Open Source Projects</a> page.
        For legal licensing we recommend you check our <a target="_blank" rel="noopener noreferrer" href="https://www.couchbase.com/legal/agreements">legal agreements</a> page.
      </p>

      <p>
        <strong>When using other database systems I found the platform we decided to use wasn't officially supported by the vendor.
        What SDKs and platforms does Couchbase support?</strong>
      </p>
      <p>
        Couchbase's various <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/sdk/overview.html">SDKs</a> supports a wide range of popular programming languages and platforms including:
      </p>

      <h2>Server SDKs</h2>

      <p>
        <ul>
          <li class="text-gray-700 text-base">
            C: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/c-sdk/current/hello-world/overview.html">https://docs.couchbase.com/c-sdk/current/hello-world/overview.html</a>
          </li>
          <li class="text-gray-700 text-base">
            Go: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/go-sdk/current/hello-world/overview.html">https://docs.couchbase.com/go-sdk/current/hello-world/overview.html</a>
          </li>
          <li class="text-gray-700 text-base">
            Java: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/java-sdk/current/hello-world/overview.html">https://docs.couchbase.com/java-sdk/current/hello-world/overview.html</a>
          </li>
          <li class="text-gray-700 text-base">
            .NET: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/dotnet-sdk/current/hello-world/overview.html">https://docs.couchbase.com/dotnet-sdk/current/hello-world/overview.html</a>
          </li>
          <li class="text-gray-700 text-base">
            Node.js: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/nodejs-sdk/current/hello-world/overview.html">https://docs.couchbase.com/nodejs-sdk/current/hello-world/overview.html</a>
          </li>
          <li class="text-gray-700 text-base">
            PHP: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/php-sdk/current/hello-world/overview.html">https://docs.couchbase.com/php-sdk/current/hello-world/overview.html</a>
          </li>
          <li class="text-gray-700 text-base">
            Python: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/python-sdk/current/hello-world/overview.html">https://docs.couchbase.com/python-sdk/current/hello-world/overview.html</a>
          </li>
          <li class="text-gray-700 text-base">
            Ruby: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/ruby-sdk/current/hello-world/overview.html">https://docs.couchbase.com/ruby-sdk/current/hello-world/overview.html</a>
          </li>
          <li class="text-gray-700 text-base">
            Scala: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/scala-sdk/current/hello-world/overview.html">https://docs.couchbase.com/scala-sdk/current/hello-world/overview.html</a>
          </li>
        </ul>
      </p>

      <h2>Mobile and Offline SDKs (Couchbase Lite/Sync Gateway)</h2>

      <p>
        <ul>
          <li class="text-gray-700 text-base">
            Java: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/couchbase-lite/current/java/quickstart.html">https://docs.couchbase.com/couchbase-lite/current/java/quickstart.html</a>
          </li>
          <li class="text-gray-700 text-base">
            Java Android: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/couchbase-lite/current/android/quickstart.html">https://docs.couchbase.com/couchbase-lite/current/android/quickstart.html</a>
          </li>
          <li class="text-gray-700 text-base">
            JavaScript: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/couchbase-lite/current/javascript.html">https://docs.couchbase.com/couchbase-lite/current/javascript.html</a>
          </li>
          <li class="text-gray-700 text-base">
            .NET/Xamarin : <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/couchbase-lite/current/csharp/quickstart.html">https://docs.couchbase.com/couchbase-lite/current/csharp/quickstart.html</a>
          </li>
          <li class="text-gray-700 text-base">
            Objective-C: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/couchbase-lite/current/objc/quickstart.html">https://docs.couchbase.com/couchbase-lite/current/objc/quickstart.html</a>
          </li>
          <li class="text-gray-700 text-base">
            Swift: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/couchbase-lite/current/swift/quickstart.html">https://docs.couchbase.com/couchbase-lite/current/swift/quickstart.html</a>
          </li>
        </ul>
      </p>

      <p>
        <strong>In the past I've ran into scaling issues with other platforms, how does Couchbase scale?</strong>
      </p>
      <p>
        Couchbase supports <a target="_blank" rel="noopener noreferrer" href="https://www.couchbase.com/multi-dimensional-scalability-overview">Multi-Dimensional Scaling</a> which allows you to
        break down which parts of Couchbase to scale. Couchbase also supports deployments to containers via <a target="_blank" rel="noopener noreferrer" href="https://www.couchbase.com/products/cloud/kubernetes">Kubernetes</a>.
        Couchbase <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/learn/clusters-and-availability/clusters-and-availability.htm">clustering and availability documentation</a> is very 
        helpful in understanding how to properly setup the cluser.
      </p>

      <p>
        <strong>Our company requires a complex setup because we operate out of several different countries. Is it possible to set up Couchbase to geo-scale across multiple data centers?</strong>
      </p>
      <p>
        Couchbase has a flexible <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/learn/clusters-and-availability/xdcr-overview.html">Cross Data Center Replication (XDCR)</a> approach to scaling.
        Our <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/learn/clusters-and-availability/replication-architecture.html">Replication Archiecture</a> documentation goes through all the various options you have.
      </p>

      <p>
        <strong>What are some of the limitations that can affect the Couchbase Server usage and implementation that I should know about?</strong>
      </p>
      <p>
        Our Couchbase Server documentation covers a wide range of topics including but not limited to how many buckets, max document size,
        and maximum memory cached and system-user connections. More information can be found in
        our <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/learn/clusters-and-availability/size-limitations.html">Size Limitation documentation</a>.
      </p>

      <p>
        <strong>How does Couchbase handle security and authenticating users?</strong>
      </p>
      <p>
        Couchbase has a flexible <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/learn/security/authentication.html">authentication setup</a> that can 
        integrate into most enterprise infrastructure.
      </p>

      <p>
        <strong>Does Couchbase offer any ways to encrypt the information, similar to other database platforms?</strong>
      </p>
      <p>
        Couchbase does support encrptyion which is explained in our <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/learn/security/encryption-overview.html">encryption documenation</a>.
      </p>

      <p>
        <strong>Does Couchbase work on mobile or other devices that require access to data when the device is offline?</strong>
      </p>
      <p>
        Couchbase has several different products that can meet the needs of mobile clients, desktop clients, or IoT devices that need the ability to sync data when offline.
      </p>
      <p>
        <ul>
          <ol>
            <p class="text-gray-700 text-base">
              <a target="_blank" rel="noopener noreferrer" href="https://www.couchbase.com/solutions/iot-data-management">https://www.couchbase.com/solutions/iot-data-management</a>
            </p>
          </ol>
          <ol>
            <p class="text-gray-700 text-base">
              <a target="_blank" rel="noopener noreferrer" href="https://www.couchbase.com/products/mobile">https://www.couchbase.com/products/mobile</a>
            </p>
          </ol>
          <ol>
            <p class="text-gray-700 text-base">
              <a target="_blank" rel="noopener noreferrer" href="https://developer.couchbase.com/mobile/">https://developer.couchbase.com/mobile/</a>
            </p>
          </ol>
        </ul>
      </p>

      <p>
        <strong>What other Enterprise customers are using Couchbase?</strong>
      </p>
      <p>
        We have a large amount of <a target="_blank" rel="noopener noreferrer" href="https://www.couchbase.com/customers">case studies</a> on our site 
        that go through various clients as they used Couchbase to build flexible and scalable solutions.
      </p>
    </div>
  )
}
export default CompetitorFaq